import { ICase, ICasesFilter, IComment } from "./cases.types";
import { ISorting } from "services/interface.service";

export const ActionTypes = {
  GET_CASES_REQUEST: "[CASES] GET CASES REQUEST",
  GET_CASES_SUCCESS: "[CASES] GET CASES SUCCESS",
  GET_CASES_FAIL: "[CASES] GET CASES FAIL",
  UPDATE_FILTER: "[CASES] UPDATE FILTER",
  UPDATE_PAGINATION: "[CASES] UPDATE PAGINATION",
  UPDATE_SORT: "[CASES] UPDATE SORT",
  GET_CURRENT_CASE_REQUEST: "[CASES] GET CURRENT CASE REQUEST",
  GET_CURRENT_CASE_SUCCESS: "[CASES] GET CURRENT CASE SUCCESS",
  GET_CURRENT_CASE_FAIL: "[CASES] GET CURRENT CASE FAIL",
  GET_COMMENTS_REQUEST: "[CASES] GET COMMENTS REQUEST",
  GET_COMMENTS_SUCCESS: "[CASES] GET COMMENTS SUCCESS",
  GET_COMMENTS_FAIL: "[CASES] GET COMMENTS FAIL",
  SET_CASE_MODAL: "[CASES] SET CASE MODAL",
} as const;

export const getCasesRequest = () => ({
  type: ActionTypes.GET_CASES_REQUEST,
})

export const getCasesSuccess = (cases: ICase[], count: number) => ({
  type: ActionTypes.GET_CASES_SUCCESS,
  payload: { cases, count },
})

export const getCasesFail = () => ({
  type: ActionTypes.GET_CASES_FAIL,
})

export const updateCasesFilter = (filter: ICasesFilter) => ({
  type: ActionTypes.UPDATE_FILTER,
  payload: filter,
})

export const updateCasesPagination = (pagination: { page: number, rowsPerPage: number }) => ({
  type: ActionTypes.UPDATE_PAGINATION,
  payload: pagination,
})

export const updateCasesSort = (sort: ISorting) => ({
  type: ActionTypes.UPDATE_SORT,
  payload: sort,
})

export const getCurrentCaseRequest = () => ({
  type: ActionTypes.GET_CURRENT_CASE_REQUEST,
})

export const getCurrentCaseSuccess = (item: ICase) => ({
  type: ActionTypes.GET_CURRENT_CASE_SUCCESS,
  payload: item,
})

export const getCurrentCaseFail = () => ({
  type: ActionTypes.GET_CURRENT_CASE_FAIL,
})

export const getCommentsRequest = () => ({
  type: ActionTypes.GET_COMMENTS_REQUEST,
})

export const getCommentsSuccess = (comments: IComment[]) => ({
  type: ActionTypes.GET_COMMENTS_SUCCESS,
  payload: comments,
})

export const getCommentsFail = () => ({
  type: ActionTypes.GET_COMMENTS_FAIL,
})

export const setCaseModal = (caseData: {
  serial_number?: string;
  id?: number;
  isOpen: boolean;
} | null ) => ({
  type: ActionTypes.SET_CASE_MODAL,
  payload: caseData,
})
