import { FC } from "react";

import { Pagination } from "antd";

import { useAppDispatch, useAppSelector } from "store";
import { getContractsSelector } from "store/contracts/contracts.selector";
import { updateEquipmentsPagination } from "store/contracts/contracts.actions";
import { getContractEquipments } from "store/contracts/contracts.thunks";
import { Loading } from "components/common";
import { extraLimit } from "services/data.service";

import "./ContractEquipmentsPagination.scss";

const ContractEquipmentPagination: FC = () => {
  const dispatch = useAppDispatch();
  const { equipmentsPagination, isExtraEquipmentsLoading } = useAppSelector(getContractsSelector);

  const handleUpdatePagination = (page: number, pageSize: number) => {
    dispatch(updateEquipmentsPagination({
      page,
      rowsPerPage: pageSize,
    }))
    dispatch(getContractEquipments());
  }

  return (
    <div className="contract-equipment-footer">
      <Loading
        isLoading={ isExtraEquipmentsLoading }
        isColored
      />

      <Pagination
        total={ equipmentsPagination.count }
        showTotal={ (total, range) => `${ range[ 0 ] }-${ range[ 1 ] } of ${ total } items` }
        pageSize={ extraLimit }
        current={ equipmentsPagination.page }
        onChange={ handleUpdatePagination }
        showSizeChanger={ false }
      />
    </div>
  )
}

export default ContractEquipmentPagination;
