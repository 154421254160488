import { Button } from "antd";

import { InviteCompleteForm, Loading, Snackbar } from "components/common";
import { useInviteComplete } from "helpers/useInviteComplete";
import closeCircle from "data/icons/close-circle.svg";
import checkCircle from "data/icons/check-circle.svg";
import mainLogo from "data/icons/hydra-logo-dark.svg";
import accountCreateSuccess from "data/images/account-create-success.svg";
import { RouteLinks } from "services/router.service";

import "./InviteOrgCompletePage.scss";

const InviteOrgCompletePage = () => {
  const {
    error,
    isAuthorized,
    isAuthorizationLoading,
    handleSubmit,
    success,
    isLoading,
  } = useInviteComplete();

  if (isAuthorizationLoading) {
    return (
      <div className="invite-org-complete"/>
    )
  }

  if (success) {
    return (
      <div className="invite-complete">
        <div className="invite-complete-left invite-complete-left-success">
          <div className="invite-complete-left-title">
            Account created <br/> successfully
          </div>

          <div>
            <img
              alt=""
              className="img"
              src={ accountCreateSuccess }
            />
          </div>

            <a href={ RouteLinks.MAIN }>
              <Button type="primary">
              Start
              </Button>
            </a>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return <Loading isLoading={ isLoading } />;
  }

  return (
    <div className="invite-org-complete">
      {!isAuthorized && (
        <div className="invite-org-complete-main">
          <div className="invite-complete-wrapper">
            <Snackbar
              gap={ 15 }
              type={ error ? "error" : "primary" }
            >
              <img
                alt=""
                src={ error ? closeCircle : checkCircle }
              />

              Your invite is {error ? "invalid" : "valid"}
            </Snackbar>

            <InviteCompleteForm
              error={ error }
              handleSubmit={ handleSubmit }
            />
          </div>
        </div>
      )}

      <div className="invite-org-complete-logo">
        <img
          alt=""
          className="logo"
          src={ mainLogo }
        />
      </div>
    </div>
  );
};

export default InviteOrgCompletePage;
