import { useState } from "react";

import { Button, Modal } from "antd";

import { InviteCompleteForm, Loading, LoginForm, Snackbar } from "components/common";
import { useInviteComplete } from "helpers/useInviteComplete";
import mainLogo from "data/icons/hydra-logo-dark.svg";
import closeCircle from "data/icons/close-circle.svg";
import accountCreateSuccess from "data/images/account-create-success.svg";
import checkCircle from "data/icons/check-circle.svg";
import loginIcon from "data/icons/login.svg";

import "./InviteOrgContractCompletePage.scss";

enum InviteStatuses {
  OK_REQUIRE_TO_LOGIN = "ok-require-login",
  OK_REQUIRE_ORG_CREATE = "ok-require-org-create",
}

const InviteOrgContractCompletePage = () => {
  const {
    check,
    error,
    success,
    info,
    isAuthorized,
    isAuthorizationLoading,
    isLoading,
    handleSubmit,
    acceptInvite,
  } = useInviteComplete(true);

  const [ isLoginOpen, setIsLoginOpen ] = useState(false);

  if (isAuthorizationLoading) {
    return (
      <div className="invite-complete"/>
    )
  }

  if (success) {
    return (
      <div className="invite-complete">
        <div className="invite-complete-left invite-complete-left-success">
          <div className="invite-complete-left-title">
            Account created <br/> successfully
          </div>

          <img
            alt=""
            className="img"
            src={ accountCreateSuccess }
          />
        </div>

        <div className="invite-complete-right invite-complete-right-success">
          <div className="title">
            Invitation to join to contract
          </div>

          <div className="text">
            Organization <span className="text-accent">{info?.inviter}</span> invited
            your organization to join to contract <span className="text-accent">{info?.contract}</span> on Hydra Platform
          </div>

          <Button
            type="primary"
            onClick={ acceptInvite }
          >
            Accept Invitation
          </Button>

          <img
            alt=""
            className="logo"
            src={ mainLogo }
          />
        </div>
      </div>
    );
  }

  if (isLoading) {
    return <Loading isLoading={ isLoading } />;
  }

  return (
    <div className="invite-complete">
      {!isAuthorized && (
        <div className="invite-complete-left">
          <div className="invite-complete-wrapper">
            <Snackbar
              gap={ 15 }
              type={ error ? "error" : "primary" }
            >
              <img
                alt=""
                src={ error ? closeCircle : checkCircle }
              />

              {check?.detail || `Your invite is ${ error ? "invalid" : "valid" }` }
            </Snackbar>

            {check?.status === InviteStatuses.OK_REQUIRE_TO_LOGIN && (
              <Button
                type="primary"
                className="invite-complete-login-button"
                onClick={ () => setIsLoginOpen(true) }
              >
                <img
                  alt=""
                  src={ loginIcon }
                />
                Log in
              </Button>
            )}

            {check?.status === InviteStatuses.OK_REQUIRE_ORG_CREATE && (
              <InviteCompleteForm
                error={ error }
                handleSubmit={ handleSubmit }
              />
            )}
          </div>
        </div>
      )}

      <div className="invite-complete-right">
        {isAuthorized && error && (
          <div className="notify-error notify-auth">
            <img
              alt=""
              src={ closeCircle }
            />

            Your invite is invalid
          </div>
        )}

        <div className="title">
          Invite to join to contract
        </div>

        {isAuthorized ? (
          <div className="text">
            Organization <span className="text-accent">{info?.inviter}</span> invited
            your organization to join to contract <span className="text-accent">{info?.contract}</span> on Hydra Platform
          </div>
        ) : (
          <div className="text">
            Organization <span className="text-accent">ООО “Официальное название компании”</span> invited
            your organization to join to contract <span className="text-accent">0000-00/АА</span> on Hydra Platform
          </div>
        )}

        <Button
          onClick={ acceptInvite }
          type="primary"
        >
          Accept invite
        </Button>

        {(!isAuthorized || error) && (
          <>
            <div className="blur"/>

            <img
              alt=""
              className="logo"
              src={ mainLogo }
            />
          </>
        )}
      </div>

      <Modal
        open={ isLoginOpen }
        onCancel={ () => setIsLoginOpen(false) }
        footer={ null }
        width={ 440 }
      >
        { isLoginOpen && (
          <div style={ { padding: "24px 16px 8px 16px" } }>
            <LoginForm
              onSuccess={ () => setIsLoginOpen(false) }
              withRedirect={ false }
            />
          </div>
        ) }
      </Modal>
    </div>
  );
};

export default InviteOrgContractCompletePage;
