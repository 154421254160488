import { Button, Form, Input } from "antd";

import { IOrganizationRegister } from "store/contracts/contracts.types";
import { formatPhoneNumber } from "services/data.service";
import { passwordValidate } from "helpers/fieldValidation";

import "./InviteCompleteForm.scss";

interface IProps {
  error: boolean;
  handleSubmit: (values: IOrganizationRegister) => void;
}

const InviteCompleteForm = ({ error, handleSubmit }: IProps) => {
  const [ form ] = Form.useForm<IOrganizationRegister>();

  return (
    <>
      <div className="invite-complete-form">
        <div className="invite-complete-form-title">
          Create organization account
        </div>

        <Form
          className="registration-form-wrapper"
          layout="vertical"
          onFinish={ handleSubmit }
          form={ form }
        >
          <Form.Item
            label="Organization Official Name"
            name="org_official_name"
            rules={ [ { required: true, message: "Please input organization official name!" } ] }
          >
            <Input
              disabled={ error }
              placeholder="e.g. ООО 'Полярная Ночь'"
            />
          </Form.Item>

          <Form.Item
            label="Organization Short Name"
            name="org_name"
            rules={ [ { required: true, message: "Please input organization short name!" } ] }
          >
            <Input
              disabled={ error }
              placeholder="e.g. ПолНочь"
            />
          </Form.Item>

          <div className="hr"/>

          <div className="invite-complete-form-subtitle">
            User info (Admin)
          </div>

          <div style={ { display: "flex" } }>
            <Form.Item
              label="First Name"
              name="first_name"
              rules={ [ { required: true, message: "Please input user first name!" } ] }
              style={ { width: "calc(50% - 10px)", display: "inline-block", marginRight: "20px" } }
            >
              <Input
                disabled={ error }
                placeholder="Add First Name"
              />
            </Form.Item>

            <Form.Item
              label="Last Name"
              name="last_name"
              rules={ [ { required: true, message: "Please input user last name!" } ] }
              style={ { width: "calc(50% - 10px)", display: "inline-block" } }
            >
              <Input
                disabled={ error }
                placeholder="Add Last Name"
              />
            </Form.Item>
          </div>

          <div style={ { display: "flex" } }>
            <Form.Item
              label="Email"
              name="email"
              rules={ [
                { required: true, message: "Please input user email!" },
                { type: "email", message: "Please input correct email!" },
              ] }
              style={ { width: "100%" } }
            >
              <Input
                disabled={ error }
                placeholder="yourname@mail.com"
              />
            </Form.Item>
          </div>

          <div style={ { display: "flex" } }>
            <Form.Item
              label="Phone"
              name="phone_number"
              style={ { width: "calc(50% - 10px)", display: "inline-block", marginRight: "20px" } }
            >
              <Input
                disabled={ error }
                onChange={ (e) => {
                  form.setFieldsValue({ org_phone: formatPhoneNumber(e.target.value) })
                } }
                placeholder="+375 (00) 000-00-00"
              />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={ passwordValidate }
              style={ { width: "calc(50% - 10px)", display: "inline-block" } }
            >
              <Input.Password disabled={ error }/>
            </Form.Item>
          </div>

          <Button
            disabled={ error }
            className="invite-complete-form-button"
            type="primary"
            htmlType="submit"
          >
            Create account
          </Button>
        </Form>
      </div>
    </>
  );
};

export default InviteCompleteForm;
