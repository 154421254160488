import { useEffect, useState } from "react";

import ReactMarkdown from "react-markdown";
import { Modal } from "antd";

import Loading from "components/common/Loading";
import api from "services/api.service";

import "./AppVersion.scss";

const AppVersion = () => {
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ versions, setVersions ] = useState<[ string, string ][]>([]);

  const onLoadVersions = async () => {
    setIsLoading(true);
    try {
      const response = await api.getVersionsHistory();

      setVersions(Object.entries(response.data));
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      onLoadVersions();
    }
  }, [ isModalOpen ]);

  return (
    <>
      <div
        className="app-version-text"
        onClick={ () => setIsModalOpen(true) }
      >
        {process.env.REACT_APP_VERSION}
      </div>

      {isModalOpen && (
        <Modal
          open={ isModalOpen }
          onCancel={ () => setIsModalOpen(false) }
          footer={ null }
          width={ 520 }
        >
          <div className="app-version-title">
            Versions
          </div>

          <Loading
            isLoading={ isLoading }
            isColored
            height={ 200 }
          >
            {versions.length ? (
              <div className="app-version-content">
                {versions.map(([ version, text ]) => (
                  <ReactMarkdown key={ version }>
                    {text}
                  </ReactMarkdown>
                ))}
              </div>
            ) : (
              <div>
                Versions not found
              </div>
            )}
          </Loading>
        </Modal>
      )}
    </>
  );
};

export default AppVersion;
