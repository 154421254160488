import { FC, useEffect, useState } from "react";

import { ColumnsType } from "antd/es/table";
import { Button, Table, Tag, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";

import { IEquipment } from "store/listEquipment/listEquipment.types";
import { CompanyItem, Loading, StatusItem } from "components/common";
import { useAppDispatch, useAppSelector } from "store";
import { getListEquipmentSelector } from "store/listEquipment/listEquipment.selector";
import "components/cases/CasesTable/CasesTable.scss";
import { getUserSelector } from "store/user/user.selector";
import { updateEquipmentsSort } from "store/listEquipment/listEquipment.actions";
import { getEquipments, getExtraEquipments } from "store/listEquipment/listEquipment.thunks";
import { setCaseModal } from "store/cases/cases.actions";
import contractIcon from "data/icons/contract.svg";
import { RouteLinks } from "services/router.service";
import { extraLimit } from "services/data.service";

import "./ListEquipmentTable.scss";

const ListEquipmentTable: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAdmin } = useAppSelector(getUserSelector);

  const { isEquipmentsLoading, equipments, sort, isExtraEquipmentsLoading, pagination } = useAppSelector(getListEquipmentSelector);

  const [ expandedRowKey, setExpandedRowKey ] = useState<number | null>(null);
  const [ updateState, setUpdateState ] = useState(false);

  useEffect(() => {
    setUpdateState(!updateState);
  }, [ isEquipmentsLoading ]);

  const columns: ColumnsType<IEquipment> = [
    {
      title: "",
      dataIndex: "status",
      key: "status",
      width: 0,
      className: "status-table-cell",
      render: (value, item) => (
        <div className="status">
          <StatusItem
            is_active={ item.is_active }
            is_pending={ item.is_pending }
          />
        </div>
      ),
    },
    {
      title: "Product Number",
      dataIndex: "product_number",
      key: "product_number",
      width: 180,
      className: "product-number-cell",
      render: (value, item) => item.product_number === "add_more"
    ? <Button type="text" onClick={ () => dispatch(getExtraEquipments()) } disabled={ isExtraEquipmentsLoading }>+ Show more</Button>
    : <Tooltip title={ value.length > 13 ? value : "" }>
        <p className={ item.is_active ? "active" : "" }>
          { value }
        </p>
      </Tooltip>,
      sortOrder: sort.column === "product_number" ? sort.direction : undefined,
      sorter: sort.column === "product_number",
      showSorterTooltip: false,
      onHeaderCell: () => ({
        id: "list-equipment-header-cell",
        onClick: () => {
          dispatch(updateEquipmentsSort({
            column: "product_number",
            direction: sort.column === "product_number" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getEquipments())
        },
      }),
      onCell: (item) => ({
        colSpan: item.product_number === "add_more" ? isAdmin ? 7 : 6 : 1,
        style: { textAlign: item.product_number === "add_more" ? "center" : "initial" },
      }),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      className: `description-table-cell ${ isAdmin ? "admin" : "" }`,
      render: (value, item) => item.product_number === "add_more" ? null : (
        <p className={ item.is_active ? "active thin" : "" }>
          {value}
        </p>
      ),
      sortOrder: sort.column === "description" ? sort.direction : undefined,
      sorter: sort.column === "description",
      showSorterTooltip: false,
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateEquipmentsSort({
            column: "description",
            direction: sort.column === "description" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getEquipments())
        },
      }),
      onCell: (item) => ({
        colSpan: item.product_number === "add_more" ? 0 : 1,
      }),
    },
    {
      title: "Serial Number",
      dataIndex: "serial_number",
      key: "serial_number",
      className: "serial-number-cell",
      width: 200,
      render: (value, item) => item.product_number === "add_more" ? null : (
        <a
          className={ item.is_active ? "active thin" : "" }
          href={ `${ RouteLinks.PROTECTED.LIST_EQUIPMENT }/${ item.id }` }
          onClick={ e => e.stopPropagation() }
        >
          {value}
        </a>
      ),
      sortOrder: sort.column === "serial_number" ? sort.direction : undefined,
      sorter: sort.column === "serial_number",
      showSorterTooltip: false,
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateEquipmentsSort({
            column: "serial_number",
            direction: sort.column === "serial_number" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getEquipments())
        },
      }),
      onCell: (item) => ({
        colSpan: item.product_number === "add_more" ? 0 : 1,
      }),
    },
    {
      title: "Support Type",
      dataIndex: "support_type",
      key: "support_type",
      width: 120,
      render: (value, item) => item.product_number === "add_more" ? null : (
        <Tooltip title={ item.support_type_name }>
          <Tag
            style={ {
              background: item.sla_ci.marker_bg_color,
              color: item.sla_ci.marker_text_color,
            } }
          >
            { value }
          </Tag>
        </Tooltip>
      ),
      sortOrder: sort.column === "support_type" ? sort.direction : undefined,
      sorter: sort.column === "support_type",
      showSorterTooltip: false,
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateEquipmentsSort({
            column: "support_type",
            direction: sort.column === "support_type" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getEquipments())
        },
      }),
      onCell: (item) => ({
        colSpan: item.product_number === "add_more" ? 0 : 1,
      }),
    },
    {
      title: "",
      dataIndex: "status",
      key: "status",
      width: 0,
      className: "status-table-cell",
      render: (value, item) => (
        <div
          className="contract-icon-wrapper"
          onClick={ () => handleOpenContract(item.contractId) }
        >
          <img src={ contractIcon } alt="" />
        </div>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "date_begin",
      key: "date_begin",
      width: 140,
      sortOrder: sort.column === "date_begin" ? sort.direction : undefined,
      sorter: sort.column === "date_begin",
      showSorterTooltip: false,
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateEquipmentsSort({
            column: "date_begin",
            direction: sort.column === "date_begin" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getEquipments())
        },
      }),
      onCell: (item) => ({
        colSpan: item.product_number === "add_more" ? 0 : 1,
        style: { minWidth: "140px" },
      }),
    },
    {
      title: "End Date",
      dataIndex: "date_end",
      key: "date_end",
      width: 140,
      sortOrder: sort.column === "date_end" ? sort.direction : undefined,
      sorter: sort.column === "date_end",
      showSorterTooltip: false,
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateEquipmentsSort({
            column: "date_end",
            direction: sort.column === "date_end" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getEquipments())
        },
      }),
      onCell: (item) => ({
        colSpan: item.product_number === "add_more" ? 0 : 1,
        style: { minWidth: "140px" },
      }),
    },
    {
      title: "Organisation",
      dataIndex: "customer",
      key: "customer",
      className: `description-table-cell ${ isAdmin ? "admin" : "" } customer`,
      render: (value, item) => (
        <CompanyItem
          email={ item.customer_email }
          has_account={ item.has_account }
          has_verified_account={ item.has_verified_account }
          official_name={ item.customer_official_name }
          phone={ item.customer_phone }
          value={ value }
        />
      ),
      width: 120,
      sortOrder: sort.column === "customer" ? sort.direction : undefined,
      sorter: sort.column === "customer",
      showSorterTooltip: false,
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateEquipmentsSort({
            column: "customer",
            direction: sort.column === "customer" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getEquipments())
        },
      }),
      onCell: (item) => ({
        colSpan: item.product_number === "add_more" ? 0 : 1,
      }),
    },
  ]

  const handleOpenContract = (contractId: number) => {
    navigate(RouteLinks.PROTECTED.CONTRACTS_ROUTES.CONTRACT + "/" + contractId);
  };

  useEffect(() => {
    const table = document.getElementById("list-equipment-table");
    if (table) {
      const body: any = table.getElementsByClassName("ant-table-body");

      if (body.length > 0) {
        body[ 0 ].onscroll = (e: any) => {
          const tbody: any = table.getElementsByClassName("ant-table-tbody");
          if (tbody.length > 0) {
            if (tbody[ 0 ].offsetHeight === e.target.scrollTop + e.target.offsetHeight) {
              dispatch(getExtraEquipments());
            }
          }
        };
      }
    }
  }, [ isEquipmentsLoading ])

  return (
    <Loading
      isLoading={ isEquipmentsLoading }
      isColored
      isFullWidth
      height={ 200 }
    >
      <Table
        className="cases-table list-equipment-table"
        id="list-equipment-table"
        columns={ isAdmin ? columns : columns.filter((item, index) => index !== 6) }
        dataSource={ (equipments.length >= extraLimit || ((pagination.page - 1) * extraLimit + equipments.length) === pagination.count) ? equipments : [ ...equipments, { product_number: "add_more", id: Math.random() } as IEquipment ] }
        rowClassName={ (item) => item.id === expandedRowKey ? "expanded" : "" }
        pagination={ false }
        rowKey={ (item) => item.id }
        expandable={ {
          expandRowByClick: true,
          expandedRowRender: (item) => (
            <div className="expanded-menu">
              <Button
                type="primary"
                size="small"
                onClick={ () => dispatch(setCaseModal({
                  id: item.id,
                  isOpen: true,
                  serial_number: item.serial_number,
                })) }
              >
                Open case
              </Button>
            </div>
          ),
          onExpand: (isExpanded, item) => setExpandedRowKey(isExpanded ? item.id : null),
          showExpandColumn: false,
          expandedRowKeys: expandedRowKey !== null ? [ expandedRowKey ] : [],
        } }
        scroll={ { y: window.innerHeight - 298 - (document.getElementById("list-equipment-header-cell")?.offsetHeight || 0) } }
        tableLayout="auto"
      />
    </Loading>
  )
}

export default ListEquipmentTable;
