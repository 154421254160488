import React from "react";

import "./Snackbar.scss";

interface ISnackbarProps {
  children: React.ReactNode;
  type: "info" | "error" | "primary";
  gap?: number;
}

const Snackbar = ({ children, type, gap }: ISnackbarProps) => {
  return (
    <div className={ `snackbar snackbar-${ type }` } style={ { gap } }>
      { children }
    </div>
  );
};

export default Snackbar;
