import { ReactNode } from "react";

import "./Section.scss";

interface ISectionProps {
  children: ReactNode;
  type: "info";
}

const Section = ({ children, type }: ISectionProps) => {
  return (
    <div className={ `section-${ type }` }>
      { children }
    </div>
  );
};

export default Section;
