import { FC } from "react";

import { Button, Modal } from "antd";

import { CaseForm } from "components/common";
import { useAppDispatch, useAppSelector } from "store";
import { getCasesSelector } from "store/cases/cases.selector";
import { setCaseModal } from "store/cases/cases.actions";

import "./CreateCaseModal.scss";

interface IProps {
  size?: "small" | "middle" | "large" | undefined;
  serial_number?: string;
  id?: number;
  block?: boolean;
}

const CreateCaseModal: FC<IProps> = ({
  size = "middle",
  block,
}) => {
  const dispatch = useAppDispatch();
  const { modal } = useAppSelector(getCasesSelector);

  return (
    <>
      <Button
        block={ block }
        type="primary"
        size={ size }
        onClick={ () => dispatch(setCaseModal({
          ...modal,
          isOpen: true,
        })) }
      >
        Open case
      </Button>

      {modal?.isOpen && (
        <Modal
          className="create-case-form"
          open={ !!modal?.isOpen }
          onCancel={ () => dispatch(setCaseModal({
            ...modal,
            isOpen: false,
          })) }
          footer={ null }
        >
          <CaseForm
            afterEvent={ () => dispatch(setCaseModal({
              ...modal,
              isOpen: false,
            })) }
            id={ modal.id }
            serial_number={ modal.serial_number }
          />
        </Modal>
      )}
    </>
  )
}

export default CreateCaseModal;
