import { useEffect, useState } from "react";

import { message } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";

import { authorizeUserSuccess } from "store/user/user.actions";
import { getUserSelector } from "store/user/user.selector";
import { useAppDispatch, useAppSelector } from "store";
import { RouteLinks } from "services/router.service";
import api from "services/api.service";

export const useInviteComplete = (isContract?: boolean) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAuthorized, isAuthorizationLoading } = useAppSelector(getUserSelector);

  const [ urlSearchParams ] = useSearchParams();
  const token = urlSearchParams.get("token") || "";

  const [ error, setError ] = useState(false);
  const [ success, setSuccess ] = useState(false);
  const [ info, setInfo ] = useState<{ inviter: string; contract: string }>();
  const [ check, setCheck ] = useState<{ detail: string; status: string }>();
  const [ isLoading, setIsLoading ] = useState(false);

  const handleSubmit = async (values: any) => {
    try {
      if (isContract) {
        await api.organizationInvitationRegister(values, token);
      } else {
        await api.organizationInvitationComplete(values, token);
      }

      try {
        const response = await api.authorizeUser(values.email, values.password);

        localStorage.setItem("AUTH_TOKEN", response.data.access);
        // setAccessToken(response.data.access);

        setSuccess(true);

        if (isContract) {
          setTimeout(async () => {
            await invitationInfo();
          }, 0);
        }
      } catch (error) {
        console.log(error);
      }

    } catch (error) {
      // @ts-ignore
      message.error(error.response.data.detail || "Something went wrong!");
    }
  };

  const invitationCheck = async () => {
    setIsLoading(true);

    try {
      const response = isContract
        ? await api.contractInvitationCheck(token)
        : await api.organizationInvitationCheck(token);

      setCheck(response.data);
      setError(false);
    } catch (e) {
      // @ts-ignore
      setCheck(e.response.data);
      setError(true);
    }

    setIsLoading(false);
  };
  const invitationInfo = async () => {
    try {
      const response = await api.contractInvitationInfo(token);

      if (response.data) {
        setInfo(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const acceptInvite = async () => {
    try {
      const response = await api.contractInvitationAccept(token);

      if (response) {
        dispatch(authorizeUserSuccess());
        navigate(RouteLinks.PROTECTED.CONTRACTS_ROUTES.CONTRACT + "/" + response.data.contract);
      }

    } catch (error) {
      // @ts-ignore
      message.error(error.response.data.detail || "Something went wrong!");
    }
  };

  useEffect(() => {
    invitationCheck();
  }, []);

  useEffect(() => {
    if (isAuthorized && isContract) {
      invitationInfo();
    }
  }, [ isAuthorized, isContract ]);

  return {
    check,
    error,
    success,
    info,
    isAuthorized,
    isAuthorizationLoading,
    isLoading,
    handleSubmit,
    acceptInvite,
  };
};
