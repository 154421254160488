import { FC, useState } from "react";

import { Button, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { Loading } from "components/common";
import { RouteLinks } from "services/router.service";
import { getUserSelector } from "store/user/user.selector";
import { authorizeUser } from "store/user/user.thunks";

import "./LoginForm.scss";

interface IProps {
  onSuccess?: () => void;
  withRedirect?: boolean;
}

const LoginForm = ({ onSuccess, withRedirect = true }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAuthorizationLoading } = useAppSelector(getUserSelector);

  const [ authorizationError, setAuthorizationError ] = useState("");

  const handleSubmit = (values: { email: string, password: string }) => {
    dispatch(authorizeUser(values.email, values.password, (error: string) => {
      setAuthorizationError(error);
    }, () => {
      if (onSuccess) {
        onSuccess();
      }

      if (withRedirect) {
        navigate(RouteLinks.PROTECTED.CASES.MAIN);
      }
    }));
  }
  const handleSubmitFailed = () => {}

  return (
    <Form
      name="basic"
      className="login-form"
      layout="vertical"
      initialValues={ { remember: true } }
      onFinish={ handleSubmit }
      onFinishFailed={ handleSubmitFailed }
      autoComplete="off"
    >
      { authorizationError !== "" && (
        <div className="login-error">{ authorizationError }</div>
      ) }
      <Form.Item
        label="Email"
        name="email"
        rules={ [ { required: true, message: "Please input your email!" } ] }
      >
        <Input/>
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        rules={ [ { required: true, message: "Please input your password!" } ] }
      >
        <Input.Password/>
      </Form.Item>
      <Form.Item>
        <Button onClick={ () => navigate(RouteLinks.RESET_PASSWORD) } type="link" name="Forgot your password?">Forgot your password?</Button>
      </Form.Item>
      <Form.Item className="mg-btn-0">
        <Button className="big-btn" type="primary" htmlType="submit">
          <Loading isLoading={ isAuthorizationLoading } height={ 24 } width={ 44 }>
            Log in
          </Loading>
        </Button>
      </Form.Item>
    </Form>
  )
}

export default LoginForm;
